<template>
  <div>
<!--    {{dayCount}}-{{dayFromTo}}-{{fromSeries}}-->
    <apexchart style="margin-top: -40px" ref="chart"   width="100%" :height="chartHeight" :options="chartOptions" :series="fromSeries"></apexchart>
  </div>
</template>
<script>

function getChartOptions(dayCount,dayFromTo,ymin,ymax,colors,xTitle,yTitle){
  let xaxisAmount = 0;
  if(dayCount == 1){
    xaxisAmount = 12;
  }else {
    xaxisAmount = dayCount;
    if(xaxisAmount >= 30){
      xaxisAmount = 15;
    }
  }
  // var fill = {};
  // if(color){
  //   fill = {fill: {
  //     colors: ['#F44300']
  //   }};
  //   alert('')
  // }
  return {
    chart: {
      // height: 350,
      type: 'scatter',
      animations:{
        enabled:false
      },
      zoom: {
        type: 'x'
      },
      toolbar: {
        show:false,
        tools:{
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,// pan: true,// reset: true,// customIcons: []
        }
      },
    },
    tooltip: {
      enabled: false
    },
    // title: {
    //   text: "chartTitle",
    //   align: 'center',
    //   offsetX: 0,
    //   offsetY: 5,
    //   floating: true,
    //   style: {
    //     fontSize:  '17px',
    //     fontWeight:  'bold',
    //     // fontFamily:  undefined,
    //     color: '#263238'
    //   },
    // },
    fill: {
      // colors: ['#F44300'],
      colors: colors,
      opacity:1,
    },
    animations: {enabled: false},
    // markers: {size: 0,},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        opacity: 0.5
      },
      column: {
        // colors: "#FF0",
        // opacity: 0.5
      },
      padding: {
        top: 0,
        right: [1,7,"1","7"].indexOf(dayCount) ? -10 :0,
        bottom: 0,
        left:  [1,7,"1","7"].indexOf(dayCount) ? 25 :0,
      },
    },
    xaxis: {
      // title:{text:xTitle},
      type: 'datetime',
      tickAmount:xaxisAmount,
      min: new Date(dayFromTo.from).getTime(),
      max: new Date(dayFromTo.to).getTime(),
      labels:{
        show:false,
        formatter: function (val,opts,tt) {
          if(dayCount == 1){
            return moment(val).format("H時");
          }
          return moment(val).format("MM/DD");
        }
      },
      tooltip: {
        enabled: false,
        formatter: function(val, opts) {
          return moment(val).format("YYYY-MM-DD HH:mm:ss");
        }
      },
    },
    yaxis: {
      title:{text:yTitle,offsetX:8},
      min: ymin,
      max: ymax,
      tickAmount:6
    }
  };
}
import moment from "moment";
//脈拍
export default {
  name: "OxPulseChartsPrint",
  props: {
    dayCount: {
      required: true
    },
    colors:{type:Array},
    ymin:{type:Number,default:0},
    ymax:{type:Number,default:300},
    // series: {type: Array, required: false},
    dayFromTo:{
      type:Object,
      "default":() => ({from:"2021-04-27 00:00:00",to:"2021-04-28 00:00:00"})
    },
    fromDate:{},
    chartType:{type:String},
    chartHeight:{default:"200"},
    chartTitle:{default:"SpO2",String},
    chartWidth:{default:"100%"},
    fromSeries:{type: Array},
    xTitle:{type:String},
    yTitle:{type:String},
  },
  mounted(){
    // this.getData();
  },
  methods:{},
  data(){
    return {
    chartFrom:null,
    chartMode:7,
    series: [],
      chartOptions: getChartOptions(this.dayCount,this.dayFromTo,this.ymin,this.ymax,this.colors,this.xTitle,this.yTitle)
  }},
  watch:{
    fromSeries(){
      this.chartOptions = getChartOptions(this.dayCount,this.dayFromTo,this.ymin,this.ymax,this.colors,this.xTitle,this.yTitle);
    }
  }
};
</script>
<style>
</style>
